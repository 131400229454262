<script lang="ts" setup>
import type {
    CategoryImageNavigationItem,
    CmsElementCategoryImageNavigation,
} from '~/types/models/cms/cmsElementTypes';
import { hashCode } from '@unhead/shared';
import { getTranslatedProperty } from '@shopware-pwa/helpers-next';

defineOptions({ inheritAttrs: false });
const props = defineProps<{
    content: CmsElementCategoryImageNavigation;
}>();

const imageOnlyView = computed<boolean>(() => !!props.content.config?.imageOnlyView?.value);
const imageOnlyViewRestrictedSize = computed<boolean>(() => !!props.content.config?.imageOnlyViewRestrictedSize?.value);

const { isLoggedIn } = useUser();
const { getCategories } = useCategoryHelper();
const fetchCategories = async (ids: string[]): Promise<CategoryImageNavigationItem[]> => {
    if (!ids.length) return [];
    const fetchedCategories = await getCategories(ids, []);
    return fetchedCategories
        .filter((category) => !category?.extensions?.attributes?.loggedInRestriction || isLoggedIn.value)
        .map((category) => {
            const categoryPath = removeForwardSlash(
                category.seoUrls?.[0]?.seoPathInfo || category.seoUrls?.[0]?.pathInfo || `/navigation/${category.id}`,
            );

            return {
                linkNewTab: !!getTranslatedProperty(category, 'linkNewTab'),
                externalLink: getTranslatedProperty(category, 'externalLink'),
                mediaUrl: category.extensions?.imageUrl?.value || '',
                categoryName: category.translated?.name || category?.name,
                categoryUrl: categoryPath,
                apiAlias: category.apiAlias || '',
            };
        });
};

const { getChildrenIds } = useCategoryHelper();
const { lastSeen } = useLastSeen('category');
const configKey = computed(() =>
    Object.entries(props.content.config)
        .map(([key, value]) => `${key}-${value?.value}`)
        .join('-'),
);
const configKeyHash = computed(() => hashCode(configKey.value));

const { data: categories, status } = useLazyAsyncData(`categoryImageNavigation-${configKeyHash.value}`, async () => {
    const source = props.content.config?.navigationSource?.value;
    if (source === 'lastSeen') return fetchCategories(lastSeen.value);
    if (source === 'selected') return fetchCategories(props.content.config?.selectedCategories?.value || []);
    if (source === 'childFromParent') {
        const childrenIds = await getChildrenIds(props.content.config?.parentCategory?.value || '');
        return fetchCategories(childrenIds || []);
    }

    return props.content.data;
});
</script>

<template>
    <template v-if="status !== 'pending' && categories?.length">
        <CategoryNavigationImageOnly
            v-if="imageOnlyView"
            :categories
            :restrict-size="imageOnlyViewRestrictedSize"
            v-bind="$attrs"
        />
        <CategoryNavigationGrid
            v-else
            :categories
            v-bind="$attrs"
        />
    </template>
</template>
